<template>
  <div
    :class="{
      'the-box': true,
      'is-loading': loading,
    }"
  >
    <div
      v-if="loading"
      class="the-box__loading"
    >
      <b-spinner
        variant="primary"
      />
    </div>
    <slot />
  </div>
</template>

<script>
import { BSpinner } from 'bootstrap-vue'

export default {
  components: {
    BSpinner,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.the-box {
  border: 1px solid #eeeeee;
  border-radius: 5px;
  padding: 30px;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.03);

  &.is-loading {
    position: relative;
    overflow: hidden;
  }

  &__loading {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    background: rgba(255, 255, 255, .8);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>

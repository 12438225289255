<template>
  <!-- eslint-disable -->
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_1896_1065" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
      <rect width="20" height="20" fill="#D9D9D9"/>
    </mask>
    <g mask="url(#mask0_1896_1065)">
      <path
        d="M4.7565 16.5833C4.39111 16.5833 4.07647 16.4513 3.81258 16.1874C3.54869 15.9235 3.41675 15.6089 3.41675 15.2435V4.75638C3.41675 4.3771 3.54869 4.05899 3.81258 3.80204C4.07647 3.5451 4.39111 3.41663 4.7565 3.41663H15.2436C15.6229 3.41663 15.941 3.5451 16.198 3.80204C16.4549 4.05899 16.5834 4.3771 16.5834 4.75638V15.2435C16.5834 15.6089 16.4549 15.9235 16.198 16.1874C15.941 16.4513 15.6229 16.5833 15.2436 16.5833H4.7565ZM15.5001 5.83169L10.1122 11.3541L7.59623 8.85892L4.50006 11.9551V13.4663L7.59623 10.3701L10.101 12.8749L15.5001 7.35248V5.83169Z"
        :fill="color"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    color() {
      return this.isActive
        ? '#7367F0'
        : '#BABABA'
    },
  },
}
</script>

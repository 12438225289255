<template>
  <label
    :class="{
      'the-checkbox': true,
      'filled': filled,
    }"
    :style="{
      '--color': color,
    }"
  >
    <input
      class="the-checkbox__input"
      type="checkbox"
      :checked="value"
      :value="value"
      @change="handleChange"
    >
    <div class="the-checkbox__checkmark">
      <CheckIcon
        v-if="value"
        :color="filled ? '#ffffff': color"
      />
    </div>
    <div
      v-if="label"
      class="the-checkbox__label"
    >
      {{ label }}
    </div>
  </label>
</template>

<script>
import CheckIcon from './icons/CheckIcon.vue'

export default {
  components: {
    CheckIcon,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '#d9d9d9',
    },
    filled: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    handleChange(event) {
      this.$emit('input', event.target.checked)
    },
  },
}
</script>

<style lang="scss">
.the-checkbox {
  $root: &;

  display: block;
  margin: 0;
  padding: 0;
  cursor: pointer;

  &__input {
    position: absolute;
    opacity: 0;
    visibility: hidden;
  }

  &__checkmark {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    border-radius: 3px;
    border: 1px solid var(--color);
  }

  &.filled {
    #{$root}__checkmark {
      background: var(--color);
    }
  }
}
</style>

export const SELECT_OPTIONS = [
  {
    label: 'Визиты',
    value: 'visits',
  },
  {
    label: 'Звонки',
    value: 'calls',
  },
  {
    label: 'Заявки',
    value: 'requests',
  },
  {
    label: 'Лиды',
    value: 'leads',
  },
]

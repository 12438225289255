<template>
  <!-- eslint-disable -->
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_1896_1077" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
      <rect width="20" height="20" fill="#BABABA"/>
    </mask>
    <g mask="url(#mask0_1896_1077)">
      <path
        d="M10.5416 9.45836V3.85901C12.0512 4.00004 13.3266 4.59111 14.3677 5.63224C15.4088 6.67338 15.9999 7.94875 16.1409 9.45836H10.5416ZM9.49192 16.1202C7.8947 15.9791 6.5579 15.3253 5.48153 14.1587C4.40514 12.992 3.86694 11.6058 3.86694 10C3.86694 8.39425 4.40514 7.00456 5.48153 5.83095C6.5579 4.65735 7.8947 4.00004 9.49192 3.85901V16.1202ZM10.5416 16.1202V10.508H16.1409C16.0031 12.0262 15.4128 13.3114 14.3701 14.3638C13.3274 15.4161 12.0512 16.0016 10.5416 16.1202Z"
        :fill="color"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    color() {
      return this.isActive
        ? '#7367F0'
        : '#BABABA'
    },
  },
}
</script>

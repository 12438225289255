<template>
  <TheBox class="analytics-warning-box">
    <div class="analytics-warning">
      <div class="analytics-warning__icon">
        <WarningIcon />
      </div>
      <div class="analytics-warning__content">
        <p>
          Данные отмеченные <span class="orange-highlight">оранжевым</span> цветом, могут быть неточными.<br>
          Сейчас там отображаются средние предположительные показатели, основанные на трафике и количестве заявок с сайта.
        </p>
        <p>
          Для подключения коллтрекинга и получения точных данных, свяжитесь пожалуйста с вашим аккаунт-менеджером.
        </p>
      </div>
    </div>
  </TheBox>
</template>

<script>
import TheBox from '@/components/the-box/TheBox.vue'
import WarningIcon from './icons/WarningIcon.vue'

export default {
  components: {
    TheBox,
    WarningIcon,
  },
}
</script>

<style lang="scss" scoped>
.analytics-warning-box {
  max-width: 540px;
}

.analytics-warning {
  display: flex;

  &__icon {
    margin-right: 20px;
  }

  &__content {
    flex: 1;
    color: #111111;
    font-size: 12px;
    font-weight: 400;
  }
}

.orange-highlight {
  color: #FE8F29;
}
</style>

var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:( _obj = {
    'the-select': true
  }, _obj[("the-select--" + _vm.variant)] = Boolean(_vm.variant), _obj[("the-select--" + _vm.size)] = Boolean(_vm.size), _obj['is-selected'] =  Boolean(_vm.selectedOption), _obj['is-opened'] =  _vm.isOpened, _obj )},[_c('div',{staticClass:"the-select__input",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.handleClickInput($event)}}},[_c('div',{staticClass:"the-select__text"},[_vm._v(" "+_vm._s(_vm.fullText)+" ")]),_c('div',{staticClass:"the-select__arrow"},[_c('ArrowIcon')],1)]),(_vm.isOpened)?_c('div',{staticClass:"the-select__drop"},_vm._l((_vm.options),function(option){return _c('div',{key:option.value,class:{
        'the-select__option': true,
        'is-active': option.value === _vm.value
      },on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.handleClickOption(option)}}},[_vm._v(" "+_vm._s(option.label)+" ")])}),0):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }
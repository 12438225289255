<template>
  <Highcharts
    :options="options"
  />
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import { DATE_FORMAT, DATE_TIME_FORMAT, DATE_TIME_RAW_FORMAT } from '@/store/analytics/constants'
import Highcharts from '@/components/highcharts/Highcharts.vue'
import { getTooltipLayout } from '@/components/highcharts/helpers'

export default {
  components: {
    Highcharts,
  },

  props: {
    metricParam: {
      type: String,
      default: '',
    },
  },

  computed: {
    ...mapGetters({
      analyticsIsChecked: 'analytics/IS_CHECKED',
      analyticsGetDiffDays: 'analytics/GET_DIFF_DAYS',
      analyticsGetDataFlat: 'analytics/GET_DATA_FLAT',
      analyticsGetDataTotal: 'analytics/GET_DATA_TOTAL',
      analyticsGetTotalCheckbox: 'analytics/GET_TOTAL_CHECKBOX',
    }),
    series() {
      const categoryLines = this.analyticsGetDataFlat.map(dataItem => ({
        name: dataItem.name,
        color: dataItem.color,
        visible: this.analyticsIsChecked(dataItem.id),
        data: dataItem.data.map(item => {
          const y = (() => {
            if (this.metricParam === 'leads') {
              return item.requests + item.calls
            }
            return item[this.metricParam]
          })()

          return {
            x: moment(item.date, DATE_TIME_RAW_FORMAT).utcOffset(0, true).valueOf(),
            y,
          }
        }),
      }))

      const totalLine = {
        name: this.analyticsGetDataTotal.name,
        color: this.analyticsGetDataTotal.color,
        visible: this.analyticsGetTotalCheckbox,
        data: this.analyticsGetDataTotal.data.map(item => {
          const y = (() => {
            if (this.metricParam === 'leads') {
              return item.requests + item.calls
            }
            return item[this.metricParam]
          })()

          return {
            x: moment(item.date, DATE_TIME_RAW_FORMAT).utcOffset(0, true).valueOf(),
            y,
          }
        }),
      }

      return [
        totalLine,
        ...categoryLines,
      ]
    },
    tickInterval() {
      if (this.analyticsGetDiffDays < 1) {
        return 4 * 3600 * 1000 // 4 hour
      }

      if (this.analyticsGetDiffDays < 11) {
        return 24 * 3600 * 1000 // one day
      }

      if (this.analyticsGetDiffDays < 49) {
        return 7 * 24 * 3600 * 1000 // one week
      }

      if (this.analyticsGetDiffDays < 367) {
        return 30 * 24 * 3600 * 1000 // one month
      }

      return 183 * 24 * 3600 * 1000 // 1/2 year
    },
    formatLabels() {
      return this.analyticsGetDiffDays < 1
        ? DATE_TIME_FORMAT
        : DATE_FORMAT
    },
    options() {
      const marker = this.analyticsGetDiffDays <= 1
        ? {
          enabled: true,
          radius: 4,
          symbol: 'circle',
        }
        : {
          enabled: false,
          radius: 2,
          symbol: 'circle',
        }

      return {
        chart: {
          type: 'spline',
          height: 250,
        },

        title: {
          text: '',
        },

        subtitle: {
          text: '',
        },

        xAxis: {
          type: 'datetime',
          tickInterval: this.tickInterval,
          tickWidth: 0,
          gridLineWidth: 1,
          lineColor: '#e6e6e6',
          minPadding: 0,
          maxPadding: 0,
          labels: {
            formatter: date => moment(date.value).format(this.formatLabels),
            style: {
              fontSize: '8px',
              color: '#111111',
            },
          },
        },

        yAxis: {
          tickPixelInterval: 100,
          title: {
            text: '',
          },
          gridLineDashStyle: 'Dash',
          opposite: true,
          lineWidth: 1,
          lineColor: '#e6e6e6',
          labels: {
            style: {
              fontSize: '8px',
              color: '#111111',
            },
          },
        },

        legend: {
          enabled: false,
        },

        tooltip: {
          shared: true,
          crosshairs: true,
          backgroundColor: null,
          borderWidth: 0,
          shadow: false,
          useHTML: true,
          style: {
            padding: 0,
          },
          className: 'custom-tooltip',
          formatter: getTooltipLayout(this.formatLabels),
        },

        plotOptions: {
          series: {
            type: 'pie',
            marker,
            states: {
              hover: {
                halo: null,
                lineWidth: 2,
              },
            },
          },
        },

        credits: {
          enabled: false,
        },

        series: this.series,
      }
    },
  },
}
</script>

<template>
  <SplineGraphic
    v-if="tabValue === 'spline'"
    :metric-param="metricParam"
  />
  <DonutGraphic
    v-else-if="tabValue === 'donut'"
    :metric-param="metricParam"
  />
</template>

<script>
import SplineGraphic from './spline-graphic/SplineGraphic.vue'
import DonutGraphic from './donut-graphic/DonutGraphic.vue'

export default {
  components: {
    SplineGraphic,
    DonutGraphic,
  },

  props: {
    tabValue: {
      type: String,
      default: '',
    },
    metricParam: {
      type: String,
      default: '',
    },
  },
}
</script>

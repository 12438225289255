import moment from 'moment'

export const getTooltipLayout = (dateFormat = 'DD.MM.YYYY') => function formatter() {
  return `
    <div class="custom-tooltip__header">
      ${moment(this.key).format(`${dateFormat}, dddd`)}
    </div>
    <div class="custom-tooltip__list">
      ${this.points.map(item => `
        <div class="custom-tooltip__item">
          <div class="custom-tooltip__circle" style="background: ${item.color}"></div>
          <div class="custom-tooltip__label">${item.series.name}</div>
          <div class="custom-tooltip__value">${item.y}</div>
        </div>
      `).join('')}
    </div>
  `
}

<template>
  <div class="analytics-info">
    <ul class="analytics-info__list">
      <li>
        <div class="option-item">
          <div class="option-item__value">
            {{ total.visits }}
          </div>
          <div class="option-item__label">
            {{ declOfNum(total.visits, ['Визит', 'Визита', 'Визитов']) }}
          </div>
        </div>
      </li>
      <li>
        <div class="option-item">
          <div class="option-item__value">
            {{ total.calls }}
          </div>
          <div class="option-item__label">
            {{ declOfNum(total.calls, ['Звонок', 'Звонка', 'Звонков']) }}
          </div>
        </div>
      </li>
      <li>
        <div class="option-item">
          <div class="option-item__value">
            {{ total.requests }}
          </div>
          <div class="option-item__label">
            {{ declOfNum(total.requests, ['Заявка', 'Заявки', 'Заявок']) }}
          </div>
        </div>
      </li>
    </ul>
    <div class="analytics-info__total">
      <div class="option-item option-item--lg">
        <div class="option-item__value">
          {{ total.calls + total.requests }}
        </div>
        <div class="option-item__label">
          Всего лидов
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { declOfNum } from './helpers'

export default {
  computed: {
    ...mapGetters({
      analyticsGetDataFlatFiltered: 'analytics/GET_DATA_FLAT_FILTERED',
    }),
    total() {
      return this.analyticsGetDataFlatFiltered.reduce((acc, cur) => {
        const getSum = key => cur.data.reduce((acc2, cur2) => acc2 + (cur2[key] || 0), 0)

        return {
          visits: acc.visits + getSum('visits'),
          calls: acc.calls + getSum('calls'),
          requests: acc.requests + getSum('requests'),
        }
      }, {
        visits: 0,
        calls: 0,
        requests: 0,
      })
    },
  },

  methods: {
    declOfNum,
  },
}
</script>

<style lang="scss" scoped>
.analytics-info {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      position: relative;
      padding-left: 16px;

      &:before {
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        width: 2px;
        height: 100%;
        background: #7367F0;
        content: '';
      }

      &:not(:last-child) {
        margin-bottom: 22px;
      }
    }
  }

  &__total {
    margin-top: auto;
  }
}

.option-item {
  $root: &;

  display: flex;
  align-items: flex-end;

  &__value {
    margin-right: 10px;
    font-size: 32px;
    font-weight: 400;
    line-height: 1;
    color: #111111;
  }

  &__label {
    font-weight: 500;
    color: #424242;
    font-size: 14px;
  }

  &--lg {
    #{$root}__value {
      font-size: 64px;
      color: #7367F0;
    }
  }
}
</style>

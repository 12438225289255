<template>
  <div class="analytics-visual">
    <div class="analytics-visual__header">
      <div class="analytics-visual__tabs">
        <AnalyticsTabs
          v-model="tabValue"
        />
      </div>
      <div class="analytics-visual__select">
        <AnalyticsSelect
          v-model="metricParam"
        />
      </div>
    </div>
    <div class="analytics-visual__content">
      <AnalyticsGraphic
        v-if="!analyticsGetLoading"
        :tab-value="tabValue"
        :metric-param="metricParam"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AnalyticsGraphic from './analytics-graphic/AnalyticsGraphic.vue'
import AnalyticsTabs from './analytics-tabs/AnalyticsTabs.vue'
import AnalyticsSelect from './analytics-select/AnalyticsSelect.vue'

export default {
  components: {
    AnalyticsGraphic,
    AnalyticsTabs,
    AnalyticsSelect,
  },

  data() {
    return {
      metricParam: 'visits',
      tabValue: 'spline',
    }
  },

  computed: {
    ...mapGetters({
      analyticsGetLoading: 'analytics/GET_LOADING',
    }),
  },
}
</script>

<style lang="scss" scoped>
.analytics-visual {
  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__tabs {
    margin-bottom: 28px;
  }

  &__content {
    min-height: 250px;
  }
}
</style>

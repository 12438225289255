<template>
  <div class="the-table__wrap">
    <table class="the-table">
      <thead>
        <tr>
          <TableTh
            v-for="column in columns"
            :key="column.key"
            :column="column"
            :sort-rule="sortRule.orderBy === column.key ? sortRule.rule : ''"
            @sort="handleSort"
          />
        </tr>
      </thead>
      <tbody v-if="loading">
        <tr
          v-for="item in 8"
          :key="item"
        >
          <td
            v-for="(_, index) in columns"
            :key="index"
          >
            <TheSkeleton
              height="17px"
            />
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr
          v-for="row in filterRows"
          :key="row.id"
          :style="{
            '--padding-left': `${row.level * 25}px`
          }"
        >
          <td
            v-for="(column, indexColumn) in columns"
            :key="column.key"
          >
            <div class="td-cell">
              <div
                v-if="row.hasChildren && indexColumn === 0"
                class="td-cell__icon"
              >
                <ToggleButton
                  :row="row"
                  :is-open="expandedRows.includes(row.id)"
                  @click="handleClickToggle(row)"
                />
              </div>
              <div class="td-cell__content">
                <slot
                  :name="`cell-${column.key}`"
                  :row="row"
                  :column="column"
                  :value="row.data[column.key]"
                >
                  {{ row.data[column.key] }}
                </slot>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import TheSkeleton from '@/components/the-skeleton/TheSkeleton.vue'
import TableTh from './table-th/TableTh.vue'
import ToggleButton from './toggle-button/ToggleButton.vue'
import { flatten, getFlattenChildrenById } from './helpers'

export default {
  components: {
    TableTh,
    ToggleButton,
    TheSkeleton,
  },

  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    rows: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      sortRule: {
        orderBy: '',
        rule: '',
      },
      expandedRows: [],
    }
  },

  computed: {
    sortedRows() {
      const sortKey = this.sortRule?.orderBy
      const sortType = this.sortRule?.rule

      if (!sortKey) {
        return this.rows
      }

      return [...this.rows].sort((a, b) => {
        const value1 = +a.data[sortKey] ? +a.data[sortKey] : `${a.data[sortKey]}`.toLowerCase()
        const value2 = +b.data[sortKey] ? +b.data[sortKey] : `${b.data[sortKey]}`.toLowerCase()

        if (value1 > value2) {
          return sortType === 'asc' ? 1 : -1
        }

        if (value1 < value2) {
          return sortType === 'asc' ? -1 : 1
        }

        return 0
      })
    },

    flattenRows() {
      return flatten(this.sortedRows)
    },

    filterRows() {
      return this.flattenRows.filter(row => {
        if (!row.parentId) {
          return true
        }

        const findParentRow = this.flattenRows.find(item => item.id === row.parentId)

        return this.expandedRows.includes(findParentRow.id)
      })
    },
  },

  mounted() {
    this.rows
      .filter(row => row.showChildren)
      .forEach(row => {
        this.expandRow(row)
      })
  },

  methods: {
    handleSort(column) {
      if (this.sortRule.orderBy === column.key) {
        this.sortRule.rule = this.sortRule.rule === 'desc' ? 'asc' : 'desc'
        return
      }

      this.sortRule = {
        orderBy: column.key,
        rule: 'asc',
      }
    },

    expandRow(row) {
      this.expandedRows.push(row.id)
    },

    collapseRow(row) {
      const childrenItems = getFlattenChildrenById(this.flattenRows, row.id)
      const closingRowIds = [
        row.id,
        ...childrenItems.map(item => item.id),
      ]
      this.expandedRows = this.expandedRows.filter(item => !closingRowIds.includes(item))
    },

    handleClickToggle(row) {
      const index = this.expandedRows.findIndex(item => item === row.id)
      if (index === -1) {
        this.expandRow(row)
      } else {
        this.collapseRow(row)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.the-table {
  width: 100%;
  caption-side: bottom;
  border-collapse: collapse;

  thead {
    tr {
      th {
        position: relative;
        padding: 14px;
        font-size: 12px;
        font-weight: 500;
        color: #111111;
        background: #f5f5f5;

        &:not(:last-child) {
          border-right: 1px solid #ffffff;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        font-size: 12px;
        font-weight: 400;
        padding: 11px 14px;
        color: #111111;

        &:not(:last-child) {
          border-right: 1px solid #eeeeee;
        }

        &:first-child {
          padding-left: var(--padding-left);
        }

        .td-cell {
          display: flex;
          align-items: center;

          &__icon {
            margin-right: 8px;
          }

          &__content {
            flex: 1;
          }
        }
      }

      &:not(:last-child) {
        border-bottom: 1px solid #eeeeee;
      }
    }
  }
}
</style>

<template>
  <div class="analytics-chart">
    <TheBox
      :loading="analyticsGetLoading"
    >
      <AnalyticsVisual />
    </TheBox>
    <TheBox
      :loading="analyticsGetLoading"
    >
      <AnalyticsInfo />
    </TheBox>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TheBox from '@/components/the-box/TheBox.vue'
import AnalyticsVisual from './analytics-visual/AnalyticsVisual.vue'
import AnalyticsInfo from './analytics-info/AnalyticsInfo.vue'

export default {
  components: {
    TheBox,
    AnalyticsVisual,
    AnalyticsInfo,
  },

  computed: {
    ...mapGetters({
      analyticsGetLoading: 'analytics/GET_LOADING',
    }),
  },
}
</script>

<style lang="scss" scoped>
.analytics-chart {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 20px;
}
</style>

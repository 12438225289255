<template>
  <div :id="id" />
</template>

<script>
import Highcharts from 'highcharts'
import ru from './locale/ru'

export default {
  props: {
    setOptions: {
      type: Object,
      default: () => {},
    },
    options: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      instance: null,
    }
  },

  computed: {
    id() {
      // eslint-disable-next-line no-underscore-dangle
      return `container-${this._uid}`
    },
  },

  watch: {
    options: {
      deep: true,
      handler() {
        if (this.instance) {
          this.instance.update(this.options)
        }
      },
    },
  },

  mounted() {
    this.init()
  },

  methods: {
    init() {
      Highcharts.setOptions({
        lang: ru,
        ...this.setOptions,
      })
      this.instance = Highcharts.chart(this.id, this.options)
    },
  },
}
</script>

<style lang="scss">
.custom-tooltip {
  & > span {
    background: #212121;
    border-radius: 7px;
    padding: 13px;
    color: #ffffff;
  }

  &__header {
    margin-bottom: 5px;
    color: #757575;
    font-size: 12px;
  }

  &__list {}

  &__item {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }

  &__circle {
    margin-right: 10px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
  }

  &__label {
    margin-right: 10px;
    font-size: 12px;
    font-weight: 500;
    color: #ffffff;
  }

  &__value {
    margin-left: auto;
    font-size: 12px;
    font-weight: 500;
    color: #ffffff;
  }
}
</style>

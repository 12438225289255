<template>
  <div class="analytics">
    <div>
      <AnalyticsFilter
        v-model="date"
        :detailing="detailing"
        @input="handleUpdateDate"
        @updateDetailing="handleUpdateDetailing"
      />
    </div>
    <div>
      <AnalyticsChart />
    </div>
    <div v-if="showWarningBlock">
      <AnalyticsWarning />
    </div>
    <div>
      <AnalyticsTable />
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import { DATE_RAW_FORMAT } from '@/store/analytics/constants'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AnalyticsFilter from './analytics-filter/AnalyticsFilter.vue'
import AnalyticsChart from './analytics-chart/AnalyticsChart.vue'
import AnalyticsTable from './analytics-table/AnalyticsTable.vue'
import AnalyticsWarning from './analytics-warning/AnalyticsWarning.vue'

export default {
  components: {
    AnalyticsFilter,
    AnalyticsChart,
    AnalyticsTable,
    AnalyticsWarning,
  },

  data() {
    return {
      date: [
        moment().subtract(6, 'd').format(DATE_RAW_FORMAT),
        moment().format(DATE_RAW_FORMAT),
      ],
      detailing: 'day',
    }
  },

  computed: {
    ...mapGetters({
      analyticsGetYandexCounterId: 'analytics/GET_YANDEX_COUNTER_ID',
      analyticsGetYandexGoalId: 'analytics/GET_YANDEX_GOAL_ID',
      analyticsGetIsApproximateCalls: 'analytics/GET_IS_APPROXIMATE_CALLS',
    }),
    showWarningBlock() {
      return this.analyticsGetIsApproximateCalls
    },
  },

  async mounted() {
    await this.analyticsFetchSettings()

    if (!this.analyticsGetYandexCounterId || !this.analyticsGetYandexGoalId) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Заполните настройки',
          text: 'Необходимо в общих настройках заполнить раздел «Аналитика»',
          icon: 'AlertTriangleIcon',
          variant: 'warning',
        },
      })

      return
    }

    await this.fetchData()
  },

  methods: {
    ...mapActions({
      analyticsFetchSettings: 'analytics/FETCH_SETTINGS',
      analyticsFetchData: 'analytics/FETCH_DATA',
    }),
    async fetchData() {
      await this.analyticsFetchData({
        date: this.date,
        detailing: this.detailing,
      })
    },
    handleUpdateDate() {
      this.detailing = 'day'
      this.fetchData()
    },
    handleUpdateDetailing(value) {
      this.detailing = value
      this.fetchData()
    },
  },
}
</script>

<style scoped lang="scss">
.analytics {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 30px;
}

::v-deep .mx-input {
  font-size: 12px;
}
</style>

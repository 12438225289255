<template>
  <div class="analytics-tabs">
    <div
      v-for="(item, index) in options"
      :key="index"
      :class="{
        'analytics-tabs__item': true,
        'is-active': value === item.value
      }"
      @click="$emit('input', item.value)"
    >
      <div class="analytics-tabs__icon">
        <component
          :is="item.icon"
          :is-active="value === item.value"
        />
      </div>
      <div class="analytics-tabs__label">
        {{ item.label }}
      </div>
    </div>
  </div>
</template>

<script>
import GraphicIcon from './icons/GraphicIcon.vue'
import DonutIcon from './icons/DonutIcon.vue'

export default {
  components: {
    GraphicIcon,
    DonutIcon,
  },

  props: {
    value: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      options: [
        {
          value: 'spline',
          label: 'График',
          icon: GraphicIcon,
        },
        {
          value: 'donut',
          label: 'Диаграмма',
          icon: DonutIcon,
        },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
.analytics-tabs {
  $root: &;

  display: flex;

  &__item {
    display: flex;
    align-items: center;
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 24px;
    }

    &.is-active {
      #{$root}__label {
        color: #111111;
      }
    }
  }

  &__icon {
    margin-right: 4px;
  }

  &__label {
    color: #616161;
    font-size: 14px;
    font-weight: 500;
  }
}
</style>

import moment from 'moment'
import { DATE_RAW_FORMAT } from '@/store/analytics/constants'

export const getDateByShortcut = tab => {
  switch (tab) {
    case 'today':
      return [moment().format(DATE_RAW_FORMAT), moment().format(DATE_RAW_FORMAT)]
    case 'yesterday':
      return [moment().subtract(1, 'd').format(DATE_RAW_FORMAT), moment().subtract(1, 'd').format(DATE_RAW_FORMAT)]
    case 'week':
      return [moment().subtract(6, 'd').format(DATE_RAW_FORMAT), moment().format(DATE_RAW_FORMAT)]
    case 'month':
      return [moment().subtract(1, 'M').format(DATE_RAW_FORMAT), moment().format(DATE_RAW_FORMAT)]
    case 'quarter':
      return [moment().subtract(3, 'M').format(DATE_RAW_FORMAT), moment().format(DATE_RAW_FORMAT)]
    case 'year':
      return [moment().subtract(12, 'M').format(DATE_RAW_FORMAT), moment().format(DATE_RAW_FORMAT)]
    default:
      return []
  }
}

export const getShortcutByDate = (date1, date2) => {
  const dateToday = moment().format(DATE_RAW_FORMAT)
  const dateYesterday = moment().subtract(1, 'd').format(DATE_RAW_FORMAT)
  const moment1 = moment(date1, DATE_RAW_FORMAT)
  const moment2 = moment(date2, DATE_RAW_FORMAT)
  const diffDays = moment2.diff(moment1, 'days', true)
  const diffMonths = moment2.diff(moment1, 'months', true)

  if (date1 === date2 && date2 === dateToday) {
    return 'today'
  }

  if (date1 === date2 && date2 === dateYesterday) {
    return 'yesterday'
  }

  if (diffDays === 6 && date2 === dateToday) {
    return 'week'
  }

  if (diffMonths === 1 && date2 === dateToday) {
    return 'month'
  }

  if (diffMonths === 3 && date2 === dateToday) {
    return 'quarter'
  }

  if (diffMonths === 12 && date2 === dateToday) {
    return 'year'
  }

  return 'custom'
}

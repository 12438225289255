<template>
  <th
    :style="{
      width: column.width
    }"
  >
    <div class="th-cell">
      <div class="th-cell__name">
        {{ column.name }}
      </div>
      <button
        v-if="column.sortable"
        :class="{
          'th-cell__sort': true,
          [`th-cell__sort--${sortRule}`]: sortRule,
        }"
        @click.prevent="handleClickSortButton"
      >
        <ArrowDown />
      </button>
    </div>
    <div
      v-if="column.mark"
      class="th-mark"
      :style="{
        '--color': column.mark
      }"
    />
  </th>
</template>

<script>
import ArrowDown from './ArrowDown.vue'

export default {
  components: {
    ArrowDown,
  },

  props: {
    column: {
      type: Object,
      default: () => {},
    },
    sortRule: {
      type: String,
      default: '',
    },
  },

  methods: {
    handleClickSortButton() {
      this.$emit('sort', this.column)
    },
  },
}
</script>

<style lang="scss" scoped>
.th-cell {
  display: flex;
  align-items: center;

  &__name {

  }

  &__sort {
    margin: 0 0 0 10px;
    padding: 0;
    background: none;
    box-shadow: none;
    border: none;
    cursor: pointer;
    outline: none;

    &--asc {
      transform: rotate(180deg);
    }
  }
}

.th-mark {
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 8px 8px 0;
  border-color: transparent var(--color) transparent transparent;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('TheTable',{key:_vm.analyticsGetLoading,attrs:{"columns":_vm.columns,"rows":_vm.rows,"loading":_vm.analyticsGetLoading},scopedSlots:_vm._u([{key:"cell-channel",fn:function(ref){
var value = ref.value;
var row = ref.row;
return [_c('div',{staticClass:"channel-td"},[_c('div',{staticClass:"channel-td__name"},[_vm._v(" "+_vm._s(value)+" ")]),_c('div',{staticClass:"channel-td__check"},[(row.id === _vm.analyticsGetDataTotal.id)?_c('the-checkbox',{attrs:{"value":_vm.analyticsGetTotalCheckbox,"color":_vm.analyticsGetDataTotal.color,"filled":true},on:{"input":function($event){return _vm.analyticsToggleTotalCheckbox()}}}):_c('the-checkbox',{attrs:{"value":_vm.analyticsIsChecked(row.id),"color":row.meta.color,"filled":row.meta.filled},on:{"input":function($event){return _vm.analyticsToggleCheckbox(row.id)}}})],1)])]}},{key:"cell-visits",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(parseFloat(value).toLocaleString('ru'))+" ")]}},{key:"cell-calls",fn:function(ref){
var value = ref.value;
return [_c('span',{class:{'is-approximate': _vm.analyticsGetIsApproximateCalls}},[_vm._v(" "+_vm._s(parseFloat(value).toLocaleString('ru'))+" ")])]}},{key:"cell-requests",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(parseFloat(value).toLocaleString('ru'))+" ")]}},{key:"cell-conversion",fn:function(ref){
var value = ref.value;
return [_c('span',{class:{'is-approximate': _vm.analyticsGetIsApproximateCalls}},[_vm._v(" "+_vm._s(value)+"% ")])]}},{key:"cell-leads",fn:function(ref){
var value = ref.value;
return [_c('span',{class:{'is-approximate': _vm.analyticsGetIsApproximateCalls}},[_vm._v(" "+_vm._s(parseFloat(value).toLocaleString('ru'))+" ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }
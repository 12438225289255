<template>
  <div class="analytics-filter">
    <Tabs
      v-model="shortcut"
      :options="shortcutOptions"
      @input="handleUpdateShortcut"
    />
    <date-picker
      :value="value"
      range
      range-separator=" — "
      format="DD MMM YYYY"
      :value-type="dateRawFormat"
      :lang="{
        formatLocale: ru,
      }"
      :clearable="false"
      @input="handleUpdateDate"
    >
      <template slot="icon-calendar">
        <img
          src="../../../assets/images/icons/calendar.svg"
          alt="icon"
        >
      </template>
    </date-picker>
    <div>
      <TheSelect
        :value="detailing"
        :options="detailingOptions"
        size="small"
        label="Детализация по"
        @input="$emit('updateDetailing', $event)"
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import DatePicker from 'vue2-datepicker'
import ru from 'vue2-datepicker/locale/ru'
import Tabs from '@/components/tabs/Tabs.vue'
import TheSelect from '@/components/the-select/TheSelect.vue'
import { DATE_RAW_FORMAT } from '@/store/analytics/constants'
import { SHORTCUT_OPTIONS, DETAILING_OPTIONS } from './constants'
import { getShortcutByDate, getDateByShortcut } from './helpers'

const momentNow = moment()

export default {
  components: {
    Tabs,
    DatePicker,
    TheSelect,
  },

  props: {
    value: {
      type: Array,
      default: () => [],
    },
    detailing: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      shortcut: '',
      shortcutOptions: [...SHORTCUT_OPTIONS],
      dateRawFormat: DATE_RAW_FORMAT,
      ru,
    }
  },

  computed: {
    ...mapGetters({
      analyticsGetDiffDays: 'analytics/GET_DIFF_DAYS',
    }),
    detailingOptions() {
      if (this.analyticsGetDiffDays < 7) {
        return [
          DETAILING_OPTIONS[0],
        ]
      }

      if (this.analyticsGetDiffDays < 32) {
        return [
          DETAILING_OPTIONS[0],
          DETAILING_OPTIONS[1],
        ]
      }

      return [
        DETAILING_OPTIONS[0],
        DETAILING_OPTIONS[1],
        DETAILING_OPTIONS[2],
      ]
    },
  },

  mounted() {
    this.updateShortcut()
  },

  methods: {
    updateShortcut() {
      this.shortcut = getShortcutByDate(this.value[0], this.value[1])
    },

    handleUpdateShortcut() {
      this.$emit('input', getDateByShortcut(this.shortcut))
    },

    handleUpdateDate(value) {
      this.$emit('input', value)
      this.$nextTick(() => {
        this.updateShortcut()
      })
    },

    handleDisabledDate(date, innerValue) {
      const currentDate = moment(new Date(date))

      if (momentNow.diff(currentDate) < 0) {
        return true
      }

      if (innerValue.length === 0 || innerValue.length === 2) {
        return false
      }

      if (innerValue.length === 1) {
        const selectedDate = moment(new Date(innerValue[0]))
        const diffDays = Math.abs(selectedDate.diff(currentDate, 'days'))
        return diffDays > 31
      }

      return false
    },
  },
}
</script>

<style lang="scss" scoped>
.analytics-filter {
  display: flex;
  gap: 30px;

  &::v-deep {
    .mx-input-wrapper {
      height: 100%;
    }

    .mx-input {
      height: 100%;
      padding-left: 30px;
      border: 1px solid #E0E0E0;

      &:hover {
        border: 1px solid #E0E0E0;
      }
    }

    .mx-icon-calendar {
      right: initial;
      left: 10px;
    }

    .mx-icon-clear {
      right: initial;
      left: 10px;
    }
  }
}
</style>

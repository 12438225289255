<template>
  <div
    :class="{
      'the-select': true,
      [`the-select--${variant}`]: Boolean(variant),
      [`the-select--${size}`]: Boolean(size),
      'is-selected': Boolean(selectedOption),
      'is-opened': isOpened,
    }"
  >
    <div
      class="the-select__input"
      @click.prevent.stop="handleClickInput"
    >
      <div class="the-select__text">
        {{ fullText }}
      </div>
      <div class="the-select__arrow">
        <ArrowIcon />
      </div>
    </div>
    <div
      v-if="isOpened"
      class="the-select__drop"
    >
      <div
        v-for="option in options"
        :key="option.value"
        :class="{
          'the-select__option': true,
          'is-active': option.value === value
        }"
        @click.prevent.stop="handleClickOption(option)"
      >
        {{ option.label }}
      </div>
    </div>
  </div>
</template>

<script>
import ArrowIcon from './icons/ArrowIcon.vue'

export default {
  components: {
    ArrowIcon,
  },

  props: {
    value: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: 'Выберите',
    },
    variant: {
      type: String,
      default: 'default',
    },
    size: {
      type: String,
      default: 'medium',
    },
    label: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      isOpened: false,
    }
  },

  computed: {
    selectedOption() {
      return this.options.find(item => item.value === this.value)
    },
    valueText() {
      if (this.selectedOption) {
        return this.selectedOption.label
      }
      return this.placeholder
    },
    fullText() {
      const label = this.label ? `${this.label}:` : ''
      return `${label} ${this.valueText}`
    },
  },

  mounted() {
    document.body.addEventListener('click', this.handleClickBody)
  },

  beforeDestroy() {
    document.body.removeEventListener('click', this.handleClickBody)
  },

  methods: {
    handleClickInput() {
      this.isOpened = !this.isOpened
    },
    handleClickOption(option) {
      this.$emit('input', option.value)
      this.isOpened = false
    },
    handleClickBody() {
      this.isOpened = false
    },
  },
}
</script>

<style lang="scss" scoped>
.the-select {
  $root: &;

  position: relative;
  display: inline-block;

  &__input {
    display: flex;
    align-items: center;
    cursor: pointer;
    border: 1px solid #e0e0e0;
    padding: 11px 10px;
    border-radius: 4px;
  }

  &__text {
    font-size: 14px;
    color: #6e6b7b;
    padding-right: 10px;
    line-height: 1.2;
  }

  &__arrow {
    transition: .2s ease-in-out;
    transform-origin: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__drop {
    position: absolute;
    top: calc(100% + 2px);
    z-index: 1;
    border: 1px solid #eeeeee;
    border-radius: 5px;
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.03);
    background: #ffffff;
    min-width: 100%;
    padding: 4px 0;
  }

  &__option {
    padding: 4px 10px;
    font-size: 14px;
    cursor: pointer;
    color: #6e6b7b;

    &:hover {
      color: #111111;
    }

    &.is-active {
      color: #111111;
    }
  }

  &.is-opened {
    #{$root}__arrow {
      transform: rotate(180deg);
    }
  }

  &.is-selected {
    #{$root}__text {
      color: #6f6c7b;
      font-weight: 400;
    }
  }

  &--small {
    #{$root}__text {
      font-size: 12px;
    }
    #{$root}__option {
      font-size: 12px;
    }
    #{$root}__arrow {
      width: 16px;
      height: 16px;
    }
  }

  &--flat {
    #{$root}__input {
      padding: 4px 0;
      border: none;
    }
    &.is-selected {
      #{$root}__text {
        color: #111111;
        font-weight: 500;
      }
    }
  }
}
</style>

export const SHORTCUT_OPTIONS = [
  {
    value: 'today',
    label: 'Сегодня',
  },
  {
    value: 'yesterday',
    label: 'Вчера',
  },
  {
    value: 'week',
    label: 'Неделя',
  },
  {
    value: 'month',
    label: 'Месяц',
  },
  {
    value: 'quarter',
    label: 'Квартал',
  },
  {
    value: 'year',
    label: 'Год',
  },
]

export const DETAILING_OPTIONS = [
  // {
  //   value: 'dekaminute',
  //   label: 'по 10 минут',
  // },
  // {
  //   value: 'hour',
  //   label: 'по часам',
  // },
  {
    value: 'day',
    label: 'по дням',
  },
  {
    value: 'week',
    label: 'по неделям',
  },
  {
    value: 'month',
    label: 'по месяцам',
  },
]

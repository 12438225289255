var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('th',{style:({
    width: _vm.column.width
  })},[_c('div',{staticClass:"th-cell"},[_c('div',{staticClass:"th-cell__name"},[_vm._v(" "+_vm._s(_vm.column.name)+" ")]),(_vm.column.sortable)?_c('button',{class:( _obj = {
        'th-cell__sort': true
      }, _obj[("th-cell__sort--" + _vm.sortRule)] = _vm.sortRule, _obj ),on:{"click":function($event){$event.preventDefault();return _vm.handleClickSortButton($event)}}},[_c('ArrowDown')],1):_vm._e()]),(_vm.column.mark)?_c('div',{staticClass:"th-mark",style:({
      '--color': _vm.column.mark
    })}):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="donut-graphic">
    <div class="donut-graphic__chart">
      <Highcharts
        class="donut-graphic__container"
        :options="options"
      />
    </div>
    <div class="donut-graphic__info">
      <DonutLegends
        :list="analyticsGetDataFlatFiltered"
        :metric-param="metricParam"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Highcharts from '@/components/highcharts/Highcharts.vue'
import DonutLegends from './DonutLegends.vue'

export default {
  components: {
    Highcharts,
    DonutLegends,
  },

  props: {
    metricParam: {
      type: String,
      default: '',
    },
  },

  computed: {
    ...mapGetters({
      analyticsGetDataFlatFiltered: 'analytics/GET_DATA_FLAT_FILTERED',
      analyticsGetDataFlat: 'analytics/GET_DATA_FLAT',
      analyticsIsChecked: 'analytics/IS_CHECKED',
    }),

    data() {
      return this.analyticsGetDataFlat.map(dataItem => {
        const y = dataItem.data.reduce((acc, item) => {
          if (this.metricParam === 'leads') {
            return acc + item.requests + item.calls
          }
          return acc + item[this.metricParam]
        }, 0)
        return {
          name: dataItem.name,
          color: dataItem.color,
          visible: this.analyticsIsChecked(dataItem.id),
          y,
        }
      })
    },

    options() {
      return {
        chart: {
          type: 'pie',
          height: 250,
          width: 250,
          spacing: 0,
        },

        series: [{
          innerSize: '60%',
          borderWidth: 0,
          data: this.data,
        }],

        tooltip: {
          backgroundColor: null,
          borderWidth: 0,
          shadow: false,
          useHTML: true,
          style: {
            padding: 0,
          },
          className: 'custom-tooltip',
          formatter() {
            return `
              <div class="custom-tooltip__item">
                <div class="custom-tooltip__circle" style="background: ${this.point.color}"></div>
                <div class="custom-tooltip__label">${this.key}</div>
                <div class="custom-tooltip__value">${this.y}</div>
              </div>
            `
          },
        },

        title: {
          text: '',
        },

        plotOptions: {
          pie: {
            borderRadius: 0,
          },
          series: {
            dataLabels: {
              enabled: false,
            },
            states: {
              hover: {
                enabled: false,
                halo: null,
                opacity: 1,
              },
              inactive: {
                enabled: false,
              },
            },
          },
        },

        credits: {
          enabled: false,
        },
      }
    },
  },
}
</script>

<style lang="scss">
.donut-graphic {
  position: relative;
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 2fr);
  align-items: center;

  &__chart {}

  &__info {

  }

  &__container {
    overflow: initial !important;

    & > * {
      overflow: initial !important;
    }
  }
}
</style>

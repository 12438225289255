<template>
  <TheTable
    :key="analyticsGetLoading"
    :columns="columns"
    :rows="rows"
    :loading="analyticsGetLoading"
  >
    <template #cell-channel="{ value, row }">
      <div class="channel-td">
        <div class="channel-td__name">
          {{ value }}
        </div>
        <div class="channel-td__check">
          <the-checkbox
            v-if="row.id === analyticsGetDataTotal.id"
            :value="analyticsGetTotalCheckbox"
            :color="analyticsGetDataTotal.color"
            :filled="true"
            @input="analyticsToggleTotalCheckbox()"
          />
          <the-checkbox
            v-else
            :value="analyticsIsChecked(row.id)"
            :color="row.meta.color"
            :filled="row.meta.filled"
            @input="analyticsToggleCheckbox(row.id)"
          />
        </div>
      </div>
    </template>
    <template #cell-visits="{ value }">
      {{ parseFloat(value).toLocaleString('ru') }}
    </template>
    <template #cell-calls="{ value }">
      <span :class="{'is-approximate': analyticsGetIsApproximateCalls}">
        {{ parseFloat(value).toLocaleString('ru') }}
      </span>
    </template>
    <template #cell-requests="{ value }">
      {{ parseFloat(value).toLocaleString('ru') }}
    </template>
    <template #cell-conversion="{ value }">
      <span :class="{'is-approximate': analyticsGetIsApproximateCalls}">
        {{ value }}%
      </span>
    </template>
    <template #cell-leads="{ value }">
      <span :class="{'is-approximate': analyticsGetIsApproximateCalls}">
        {{ parseFloat(value).toLocaleString('ru') }}
      </span>
    </template>
  </TheTable>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import TheCheckbox from '@/components/the-checkbox/TheCheckbox.vue'
import TheTable from '@/components/the-table/TheTable.vue'
import { COLUMNS } from './constants'

export default {
  components: {
    TheTable,
    TheCheckbox,
  },

  props: {
    checkboxes: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    ...mapGetters({
      analyticsGetData: 'analytics/GET_DATA',
      analyticsGetDataTotal: 'analytics/GET_DATA_TOTAL',
      analyticsGetLoading: 'analytics/GET_LOADING',
      analyticsIsChecked: 'analytics/IS_CHECKED',
      analyticsGetTotalCheckbox: 'analytics/GET_TOTAL_CHECKBOX',
      analyticsGetIsApproximateCalls: 'analytics/GET_IS_APPROXIMATE_CALLS',
    }),
    columns() {
      return COLUMNS.map(column => {
        const isShowMark = this.analyticsGetIsApproximateCalls && ['calls', 'conversion', 'leads'].includes(column.key)

        const mark = isShowMark
          ? '#FE8F29'
          : ''

        return {
          ...column,
          mark,
        }
      })
    },
    rows() {
      const getRow = item => {
        const children = item.children.map(sub => getRow(sub))
        const visits = item.data.reduce((acc, cur) => acc + cur.visits, 0)
        const calls = item.data.reduce((acc, cur) => acc + cur.calls, 0)
        const requests = item.data.reduce((acc, cur) => acc + cur.requests, 0)
        const leads = requests + calls
        const conversion = !visits
          ? 0
          : Math.round((leads * 100) / visits)

        return {
          id: item.id,
          showChildren: false,
          meta: {
            color: item.color,
            filled: item.level === 0,
          },
          data: {
            channel: item.name,
            visits,
            calls,
            requests,
            conversion,
            leads,
          },
          children,
        }
      }

      const rows = this.analyticsGetData.map((item, index) => getRow(item, index))
      const getTotal = () => {
        const visits = rows.reduce((acc, cur) => acc + cur.data.visits, 0)
        const calls = rows.reduce((acc, cur) => acc + cur.data.calls, 0)
        const requests = rows.reduce((acc, cur) => acc + cur.data.requests, 0)
        const leads = requests + calls
        const conversion = !visits
          ? 0
          : Math.round((leads * 100) / visits)

        return {
          id: this.analyticsGetDataTotal.id,
          data: {
            channel: this.analyticsGetDataTotal.name,
            visits,
            calls,
            requests,
            conversion,
            leads,
          },
        }
      }

      return [
        getTotal(),
        ...rows,
      ]
    },
  },

  methods: {
    ...mapActions({
      analyticsToggleCheckbox: 'analytics/TOGGLE_CHECKBOX',
      analyticsToggleTotalCheckbox: 'analytics/TOGGLE_TOTAL_CHECKBOX',
    }),
  },
}
</script>

<style lang="scss">
.channel-td {
  display: flex;
  justify-content: space-between;
}

.is-approximate {
  color: #FE8F29;
}
</style>

export const flatten = (items = [], parentId = '', level = 0) => {
  const flat = []

  items.forEach(item => {
    const hasChildren = Boolean(Array.isArray(item.children) && item.children.length)

    flat.push({
      id: item.id,
      data: item.data,
      meta: item.meta || {},
      parentId,
      level,
      hasChildren,
    })

    if (hasChildren) {
      flat.push(...flatten(item.children, item.id, level + 1))
    }
  })

  return flat
}

export const getFlattenChildrenById = (items, id) => {
  const get = currentId => {
    const children = items.filter(item => item.parentId === currentId)

    return [
      ...children,
      ...children.map(item => get(item.id)).flat(),
    ]
  }

  return get(id)
}

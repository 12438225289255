<template>
  <ul class="donut-legends">
    <li
      v-for="(item, index) in list"
      :key="index"
    >
      <div
        :class="{
          'donut-legends__item': true,
          'is-root': level === 0
        }"
        :style="{
          paddingLeft: `${14 * level}px`
        }"
      >
        <div
          class="donut-legends__circle"
          :style="{
            '--color': item.color
          }"
        />
        <div class="donut-legends__label">
          {{ item.name }}
        </div>
        <div class="donut-legends__value">
          {{ getValue(item) }}
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'DonutLegends',

  props: {
    list: {
      type: Array,
      default: () => [],
    },
    level: {
      type: Number,
      default: 0,
    },
    metricParam: {
      type: String,
      default: '',
    },
  },

  methods: {
    getValue(dataItem) {
      return dataItem.data.reduce((acc, item) => {
        if (this.metricParam === 'leads') {
          return acc + item.requests + item.calls
        }
        return acc + item[this.metricParam]
      }, 0)
    },
  },
}
</script>

<style lang="scss">
.donut-legends {
  $root: &;

  margin: 0;
  padding: 0;
  list-style: none;

  &__item {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f4f4f4;
    padding-top: 4px;
    padding-bottom: 4px;

    &.is-root {
      font-weight: 500;

      #{$root}__circle {
        background: var(--color);
      }
    }
  }

  &__circle {
    margin-right: 8px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    border: 1px solid var(--color);
  }

  &__label {
    margin-right: 10px;
    font-size: 12px;
    color: #111111;
  }

  &__value {
    margin-left: auto;
    font-size: 12px;
    color: #111111;
  }
}
</style>

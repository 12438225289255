<template>
  <!-- eslint-disable -->
  <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.66678 7.11485L0.908447 4.35652L1.88996 3.375L3.66678 5.15182L8.11026 0.708336L9.09178 1.68985L3.66678 7.11485Z" :fill="color" />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#ffffff',
    },
  },
}
</script>

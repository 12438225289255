<template>
  <div
    class="the-skeleton"
    :style="{
      width,
      height,
      borderRadius
    }"
  />
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: '100%',
    },
    height: {
      type: String,
      default: '20px',
    },
    borderRadius: {
      type: String,
      default: '4px',
    },
  },
}
</script>

<style lang="scss" scoped>
.the-skeleton {
  background: #d9d9d9;
  animation: pulse 2s cubic-bezier(.4, 0, .6, 1) infinite;
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}
</style>

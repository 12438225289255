export const COLUMNS = [
  {
    key: 'channel',
    name: 'Канал',
    width: '540px',
  },
  {
    key: 'visits',
    name: 'Визиты',
    sortable: true,
  },
  {
    key: 'calls',
    name: 'Звонки',
    sortable: true,
  },
  {
    key: 'requests',
    name: 'Заявки',
    sortable: true,
  },
  {
    key: 'conversion',
    name: 'Конверсия',
  },
  {
    key: 'leads',
    name: 'Все лиды',
  },
]

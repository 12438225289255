var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"donut-legends"},_vm._l((_vm.list),function(item,index){return _c('li',{key:index},[_c('div',{class:{
        'donut-legends__item': true,
        'is-root': _vm.level === 0
      },style:({
        paddingLeft: ((14 * _vm.level) + "px")
      })},[_c('div',{staticClass:"donut-legends__circle",style:({
          '--color': item.color
        })}),_c('div',{staticClass:"donut-legends__label"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"donut-legends__value"},[_vm._v(" "+_vm._s(_vm.getValue(item))+" ")])])])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="analytics-select">
    <div class="analytics-select__label">
      Параметр
    </div>
    <TheSelect
      :value="value"
      :options="selectOptions"
      variant="flat"
      size="small"
      @input="$emit('input', $event)"
    />
  </div>
</template>
<script>
import TheSelect from '@/components/the-select/TheSelect.vue'
import { SELECT_OPTIONS } from './constants'

export default {
  components: {
    TheSelect,
  },

  props: {
    value: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      selectOptions: SELECT_OPTIONS,
    }
  },
}
</script>

<style lang="scss" scoped>
.analytics-select {
  display: flex;
  align-items: center;

  &__label {
    margin-right: 20px;
    font-size: 12px;
  }
}
</style>

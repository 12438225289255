<template>
  <button
    :class="{
      'toggle-button': true,
      'is-open': isOpen
    }"
    @click.prevent="handleClick"
  >
    <MinusIcon
      v-if="isOpen"
    />
    <PlusIcon
      v-else
    />
  </button>
</template>

<script>
import PlusIcon from './PlusIcon.vue'
import MinusIcon from './MinusIcon.vue'

export default {
  components: {
    PlusIcon,
    MinusIcon,
  },

  props: {
    row: {
      type: Object,
      default: () => {},
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    handleClick() {
      this.$emit('click')
    },
  },
}
</script>

<style lang="scss" scoped>
.toggle-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  margin: 0;
  padding: 0;
  box-shadow: none;
  outline: none;
  cursor: pointer;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  background: #eeeeee;

  &:active {
    background: #ffffff;
  }

  &.is-open {
    background: #ffffff;

    &:active {
      background: #eeeeee;
    }
  }
}
</style>
